/**
 * Created by kdeit on 16/09/15.
 */

"use strict";
var currentSliderId = 0;
var photoCount = 15; //Сколько картинок подгружать
var startCount = 0; //Счётчик подгрузки

/*$("img").lazyload({
 effect: "fadeIn"
 });
 */

function randomShowElements() {
    $('.grid-item').each(function (index, element) {

        if ($(this).hasClass('flow')) {
            var currentWidth = $(this).find('img').width();
            $(this).width(currentWidth);
        }

        var sleepTime = Math.floor(Math.random() * 10);
        var randomTimeout = setTimeout(function () {
            var d = Math.floor(Math.random() * 2000);
            $(element).fadeTo(d, 1);
        }, sleepTime);
    });
    ajaxMore();
    //Идея в том, что бы подгружать фотки без проверки экрана или
    //скрола. Просто если не достигнуто максимальное значение
}

$(window).resize(function () {
    fotoAutoHeight($('img.img-responsive'));
});


$(function () {

    if ($('.gridWrapper').length > 0) {
        $('.gridWrapper').imagesLoaded(function () {
            randomShowElements();
        });
    }


    fotoSliderCurrentPhotoCheck();
    lightboxCheck();

    if (Cookies.get('lightboxCount') == null) {
        Cookies.set('lightboxCount', '0')
    }

    $('.thumbButton').on('click', function (e) {
        e.preventDefault();
        var text = $(this).text();
        $(this).text(
            text == "Show all" ? "Hide all" : "Show all");

        $('.thumbnails').fadeToggle(function () {
            if ($(this).height > 0) {
                $(this).hide();
            }
            fotoAutoHeight($('img.img-responsive'));
        });
    });


    $('.addLightBox').on('click', function (event) {
        event.preventDefault();
        var cookieArr = Cookies.get('lightbox');

        if (typeof cookieArr === 'undefined') {
            n = -1;
            cookieArr = '';
        } else {
            var n = cookieArr.search('&' + currentSliderId + '&');
        }
        if (n != -1) {
            cookieArr = cookieArr.replace(currentSliderId, "");
            $('a.addLightBox').text('Add to lightbox');
            var n = Number(Cookies.get('lightboxCount'));
            Cookies.set('lightboxCount', n - 1, {expires: 7});
            lightboxCheck();

        } else {
            cookieArr = cookieArr + '&' + currentSliderId + '&';
            $('a.addLightBox').text('Remove from lightbox');
            var n = Number(Cookies.get('lightboxCount'));
            Cookies.set('lightboxCount', n + 1, {expires: 7});
            lightboxCheck()
        }
        Cookies.set('lightbox', cookieArr, {expires: 7});
    }); //add to lightbox

    $('.remove_from_lightbox').on('click', function (event) {
        event.preventDefault();

        var cookieArr = Cookies.get('lightbox');
        currentSliderId = $(this).attr('data-image-id');
        cookieArr = cookieArr.replace('&' + currentSliderId + '&', "");
        var n = Number(Cookies.get('lightboxCount')) - 1;
        Cookies.set('lightboxCount', n, {expires: 7});
        lightboxCheck();
        Cookies.set('lightbox', cookieArr, {expires: 7});
        $(this).parent().fadeOut();

        if (n < 1) {
            Cookies.set('lightboxCount', '', {expires: 7});
            Cookies.set('lightbox', '', {expires: 7});
            location.reload();
        }


    }); //remove from lightbox

    $('.desoslide-wrapper').mousemove(function (event) {
        if (event.pageX < ($(window).width() / 2)) {
            $(this).addClass('leftPointer');
            $(this).removeClass('rightPointer');
        } else {
            $(this).removeClass('leftPointer');
            $(this).addClass('rightPointer');
        }
    });

    $('.thumbnails a').on('click', function () {
        $('.thumbnails a').removeClass('currentFoto');
        $(this).addClass('currentFoto');
        var currentSliderNumber = Number($(this).attr('data-desoslide-index'));
        $('span.currentFoto').text(currentSliderNumber + 1);
    });

    $('.desoslide-wrapper').on('click', function (event) {
        if (event.pageX < ($(window).width() / 2)) {
            $('#slideshow').desoSlide('goPrev');
        } else {
            $('#slideshow').desoSlide('goNext');
        }

    });

    $('#slideshow').on({
        'imageShow.desoslide': function () {
            fotoSliderCurrentPhotoCheck();
        }
    });

    $('#slideshow').on({
        'next.desoslide': function () {
            fotoSliderCurrentPhotoCheck();
        }
    });


    $('.responsiveButton').click(function (event) {
        event.preventDefault();
        $('.main_wrapper').toggleClass('active');
        $('nav').toggleClass('open');

        if ($('nav').hasClass('open')) {
            if ($('#wrapper').height() < $('nav').innerHeight()) {
                $('#wrapper').height($('nav').innerHeight());
            }

        } else {
            $('#wrapper').css({
                'height': 'auto'
            });
        }

    });


    $('.bottomNav .download').on('click', function (event) {
        event.preventDefault();
        var bigPictureUrl = $('#slideshow img.img-responsive').attr('src');
        var currentSlider = $('.thumbnails a[href="' + bigPictureUrl + '"]');
        currentSliderId = currentSlider.attr('data-imageId');
        $('.feedback input[name="photoID"]').val(currentSliderId);
        $('.formIsHidden .selectBlock').fadeTo('fast', 1);
        $('.formIsHidden form').fadeTo('fast', 0).hide();
        $('.feedback form input[name="email"]').show();
        $('.feedback form input[type="submit"]').val('Send').removeClass('noClickable').show();
        $('.feedback form label[for="email"]').text('Enter your email address below. Once your PDF is generated, we will send you a notification email with a link to download it.');
        $('.backShadow').show();
        $('.feedback').show();
    });

    $('.bottomNav .shareButton').on('click', function (event) {
        event.preventDefault();
        $('.backShadow').show();
        $('.share').show();
    });

    $('.feedback .close, .share .close, .backShadow').on('click', function () {
        $('.backShadow').hide();
        $('.feedback').hide();
        $('.share').hide();
    });

    $('.selectBlock a').on('click', function (event) {
        event.preventDefault();
        $('.feedback input[name="selectDownload"]').val(
            $(this).attr('data-select')
        );
        $('.selectBlock').fadeTo('slow', 0);
        $('.feedback form').fadeTo('slow', 1);
    });

    $('.feedback form').on('submit', function () {
        $('.feedback form input[type="submit"]').addClass('noClickable').val('Sending');
        setTimeout(ajaxDownload, 1000);
        return false;
    });//eof ajax

    $('.subscribes_form form').on('submit', function () {
        $('.subscribes_form form input[type="submit"]').addClass('noClickable').val('Sending');
        setTimeout(ajaxSubscribes, 1000);
        return false;
    });//eof ajax


    $('.sendButton').on('click', function () {
        $('.backShadow').show();
        $('.feedback').show();
    });
});

function ajaxMore() {
    startCount = startCount + photoCount;

    if (shooting_count <= startCount) {
        $('.loading').remove();
        console.log('that all kids');
        return;
    }

    console.log('start ajax');
    console.log(startCount);
    $.ajax({
        type: "GET",
        url: window.location.href + '/start',
        data: {
            'start': startCount
        },
        success: function (msg) {

            var element = $(msg);
            var loadCounter = 0;
            var loadLength = element.length;

            $.each(element, function (index, value) {
                if (!$(value).hasClass('grid-item')) {
                    console.log('remove');
                    loadCounter++;
                    return
                }

                var i = new Image();
                //console.log($(value).find('img').attr('src'));
                i.src = $(value).find('img').attr('src');
                i.onload = function () {
                    loadCounter++;
                    $('.loading').remove();
                    $('.grid').append($(element));

                    var sleepTime = Math.floor(Math.random() * 10);
                    var randomTimeout = setTimeout(function () {
                        var d = Math.floor(Math.random() * 2000);
                        $(element[index]).fadeTo(d, 1);
                    }, sleepTime);


                    $('.grid').append('<div class="loading"></div>');

                    if (loadCounter == loadLength) {
                        console.log('one more');
                        ajaxMore();
                    }
                };

                //$(this).append(i);
            });
        }
    });
}
function ajaxDownload() {

    $.ajax({
        type: "POST",
        data: {
            'email': $('input[name=email]').val(),
            '_token': $('input[name=_token]').val(),
            'selectDownload': $('input[name=selectDownload]').val(),
            'photoID': $('input[name=photoID]').val(),
            'shootingID': $('input[name=shootingID]').val(),
            'categoryType': $('input[name=categoryType]').val()
        },
        success: function (msg) {
            $('.feedback form').fadeTo('slow', 0);
            $('.feedback form input[name="email"]').hide();
            $('.feedback form input[type="submit"]').hide();
            $('.feedback form label').text('You will be emailed a notification with a link once the file is generated');
            $('.feedback form').fadeTo('slow', 1);
        },
        error: function (xhr) {
            var errors = xhr.responseText;
            $('.feedback .message').text('An error occurred while processing the request.');
            $('.feedback form input[type="submit"]').hide();

        }
    });
}
function ajaxSubscribes() {
    $.ajax({
        type: "POST",
        data: {
            'email': $('input[name=email]').val(),
            '_token': $('input[name=_token]').val()
        },
        url: '/subscribes',
        success: function (msg) {
            $('.subscribes_form form').fadeTo('slow', 0);
            $('.subscribes_form form input[name="email"]').hide();
            $('.subscribes_form form input[type="submit"]').hide();
            $('.subscribes_form form label').text('You will be emailed a notification with a link once the file is generated');
            $('.subscribes_form form').fadeTo('slow', 1);
        },
        error: function (xhr) {
            var errors = xhr.responseText;
            $('.subscribes_form .message').text('An error occurred while processing the request.');
            $('.subscribes_form form input[type="submit"]').hide();
        }
    });
}
function fotoSliderCurrentPhotoCheck() {
    console.log('fotoSliderCurrentPhotoCheck');
    $('.thumbnails a').removeClass('currentFoto');
    var bigPictureUrl = $('#slideshow img.img-responsive').attr('src');
    var currentSlider = $('.thumbnails a[href="' + bigPictureUrl + '"]');
    currentSlider.addClass('currentFoto');
    var currentSliderNumber = Number(currentSlider.attr('data-desoslide-index'));
    currentSliderId = currentSlider.attr('data-imageId');

    $('span.currentFoto').text(currentSliderNumber + 1);

    //////lightbox text
    var cookieArr = Cookies.get('lightbox');
    if (typeof cookieArr === 'undefined') {
        n = -1;
    } else {
        var n = cookieArr.search('&' + currentSliderId + '&');
    }

    if (n != -1) {
        $('a.addLightBox').text('Remove from lightbox');
    } else {
        $('a.addLightBox').text('Add to lightbox');
    }
}
function fotoAutoHeight(img) {
    // Get on screen image

    var screenImage = $(img);

// Create new offscreen image to test
    var theImage = new Image();
    theImage.src = screenImage.attr("src");

    theImage.onload = function () {
        var imageWidth = theImage.width;
        var imageHeight = theImage.height;
        var ratio_original = imageHeight / imageWidth;
        var heightCount = $(window).height() - $('header').outerHeight() - $('.thumb').outerHeight() - 5;
        //.thumb - строчка внизу
        console.log(heightCount);

        if (heightCount > 300) {
            var new_width = heightCount / ratio_original;
            if (new_width < $(window).width() || ratio_original > 1.5) {
                $(img).height(heightCount);
                $(img).css({'width': 'auto'});
            } else {
                $(img).css({'height': 'auto'});
                $(img).css({'width': '100%'});
                $(img).parent().height(heightCount);
            }
        }
    }
}
function lightboxCheck() {
    if (Number(Cookies.get('lightboxCount')) > 0) {
        $('.lightboxCount').text('(' + Cookies.get('lightboxCount') + ')');
    } else {
        $('.lightboxCount').text('');
    }
}